<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.63604 5.90362C7.32387 4.21579 9.61305 3.26758 12 3.26758C14.3869 3.26758 16.6761 4.21579 18.364 5.90362C20.0518 7.59144 21 9.88063 21 12.2676C21 13.4495 20.7672 14.6198 20.3149 15.7117C19.8626 16.8037 19.1997 17.7958 18.364 18.6315C17.5282 19.4673 16.5361 20.1302 15.4442 20.5825C14.3522 21.0348 13.1819 21.2676 12 21.2676C10.8181 21.2676 9.64778 21.0348 8.55585 20.5825C7.46392 20.1302 6.47177 19.4673 5.63604 18.6315C4.80031 17.7958 4.13738 16.8037 3.68508 15.7117C3.23279 14.6198 3 13.4495 3 12.2676C3 9.88063 3.94821 7.59144 5.63604 5.90362ZM12 7.76758C12.4971 7.76758 12.9 8.17052 12.9 8.66758V12.2676C12.9 12.7646 12.4971 13.1676 12 13.1676C11.5029 13.1676 11.1 12.7646 11.1 12.2676V8.66758C11.1 8.17052 11.5029 7.76758 12 7.76758ZM11.1 15.8676C11.1 15.3705 11.5029 14.9676 12 14.9676H12.009C12.5061 14.9676 12.909 15.3705 12.909 15.8676C12.909 16.3646 12.5061 16.7676 12.009 16.7676H12C11.5029 16.7676 11.1 16.3646 11.1 15.8676Z"
      fill="#F09035"
    />
  </svg>
</template>
    
    <script>
import { Vue } from "vue-class-component";

export default class WarningCircleFill extends Vue {}
</script>
    